











































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import EmployeeDto, {UpdateEmployeePayload} from "@/dto/payroll/EmployeeDto";
import BranchDto from "@/components/profile/branches/BranchDtos";
import EmployeeService from "@/services/EmployeeService";
import TerminationModal from "@/components/payroll/business/employments/contractors/TerminationModal.vue";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import RouteNames from "@/router/RouteNames";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import SelectOption from "@/components/common/SelectOption";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import AddressDTO from "@/dto/AddressDTO";
import Notifications from "@/state/Notifications";
import {ProjectDTO} from "@/dto/payroll/ProjectDTOs";
import Application from "@/state/Application";
import CompanyService from "@/services/CompanyService";


@Component({
	computed: {
		SelectOption() {
			return SelectOption
		},
		WorkspaceTypePathElement() {
			return WorkspaceTypePathElement
		}
	},
	methods: {
		RouteNames() {
			return RouteNames
		}
	},
	components: {PortalCheckbox, BTPortalCollapse, PortalInput, PortalDate, PortalSelect}
})
export default class Details extends Vue {

	private detailsIsOpen = true;

	private message: string | null = null;

	private successful = false;

	private projects: ProjectDTO[] = [];

	@Prop()
	private employee!: EmployeeDto

	get branches(): Array<SelectOption> {
		let branches: Array<BranchDto> = [];
		if (this.employee.employer?.headquarter) {
			branches.push(this.employee.employer.headquarter);
		}
		if (this.employee.employer?.branches) {
			branches.push(...this.employee.employer.branches)
		}
		return branches.map(branch => SelectOption.builder().title(branch.title).value(branch).build());
	}

	public created(): void {
		this.loadProjects()
	}

	private loadProjects(): void {
		Application.startLoading()
		CompanyService.getProjectsByCompanyId(this.employee.employer?.id).then(
			res => {
				this.projects = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	get projectOptions(): SelectOption[] {
		return this.projects.map(it => SelectOption.builder().title(it.title).value(it).build())
	}

	private applyWorkplace(): void {
		if (this.employee.workplaces.length > 0) {
			this.employee.remote = false;
		}
	}

	get workplaces(): Array<SelectOption> {
		let workplaces: Array<AddressDTO> = [];
		this.employee.branches.forEach(branch => workplaces.push(...this.assembleAddresses(branch)))
		return workplaces.map(place => SelectOption.builder().title(place.fullAddress).value(place).build());
	}

	private assembleAddresses(branch: BranchDto) {
		let res: Array<AddressDTO> = [];
		if (branch.legalAddress && branch.legalAddress.fullAddress) {
			res.push(branch.legalAddress)
		}
		if (branch.actualAddress && branch.actualAddress.fullAddress) {
			res.push(branch.actualAddress)
		}
		if (branch.mailingAddress && branch.mailingAddress.fullAddress) {
			res.push(branch.mailingAddress)
		}
		if (branch.registrationAgentAddress && branch.registrationAgentAddress.fullAddress) {
			res.push(branch.registrationAgentAddress)
		}
		return res;
	}

	private openTerminateModal():void {
		this.$modal.show(
			TerminationModal,
			{
				terminateId: this.employee.id,
				applyTerminate: (payload: TerminationPayloadDTO) => this.terminate(payload)
			},
			{
				height: "auto"
			}
		)
	}

	private terminate(payload: TerminationPayloadDTO): void {
		Application.startLoading();
		EmployeeService.terminateContractor(payload).then(
			() => {
				this.successful = true;
				this.$router.push({name: RouteNames.HR_EMPLOYEES, params: {companyId: String(this.employee.employer?.id)}})
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private deleteEmployee(): void {
		Application.startLoading()
		EmployeeService.deleteContractor(this.employee.id as number).then(
			() => {
				Application.stopLoading()
				this.$router.push({name: RouteNames.HR_EMPLOYEES, params: {companyId: String(this.employee.employer?.id)}})
			},
			err => processError(err, this)
		)
	}

	private update(): void {
		ifValid(this, () => {
			Application.startLoading()
			const req = new UpdateEmployeePayload({
				id: this.employee.id,
				employmentStart: this.employee.employmentStart,
				branches: this.employee.branches,
				workplaces: this.employee.workplaces,
				remote: this.employee.remote,
				projectsId: this.employee.projects.map(it => it.id),
				officer: this.employee.officer
			})
			EmployeeService.updateContractor(req).then(
				() => {
					this.successful = true;
					Notifications.success("The employee's data has been updated")
					this.$emit("update")
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		})
	}

}

